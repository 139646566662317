import logo from './logo.svg';
import './App.css';
import Home from './components/home';
import MyComponent from './components/test';

function App() {
  return (
    <div className="App">
      <Home></Home>
      {/* <MyComponent /> */}
    </div>
  );
}

export default App;
