import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import { Col, Form, Label, Input, FormGroup, Button, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import { FieldArray, Formik } from 'formik';

import { FieldArray, Formik, useFormik } from 'formik';
import * as Yup from 'yup'
import Header from './Header/header';
import NavBar from './Header/Navbar';



const Home = () => {



    const form = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    // const [formSections, setFormSections] = useState([
    //     { id: 1, name: '', gender: '', age: '' }
    // ]);

    // Handler to add a new form section
    // const addFormSection = () => {
    //     setFormSections(prevSections => [
    //         ...prevSections,
    //         { id: prevSections.length + 1, name: '', gender: '', age: '' }
    //     ]);
    // };


    // async function sendEmail() {



    //         let mailOptions = {
    //             from: "rajeev@appzoy.com",
    //             to: [

    //                 // { name: "Anusha", address: "anushaj@appzoy.com" },
    //                 { name: "Rajeev", address: "rajeevkrsingh1202@gmail.com" }
    //             ],

    //             subject: "testting email service",
    //             text: `Hi, \n\n Database backup performe`,
    //         };
    //         const smtpConfig = {
    //             host: 'mail.appzoy.com', // Your company's SMTP server
    //             port: 587,                // SMTP port (587 for TLS, 465 for SSL)
    //             secure: false,            // true for 465, false for other ports
    //             auth: {
    //                 user: 'rajeev@appzoy.com', // Your email address
    //                 pass: 'R@jeev12'     // Your email account password
    //             },
    //             tls: {
    //                 rejectUnauthorized: false // Use this only if you're having issues with self-signed certificates
    //             }
    //         };
    //         let transporter = await nodemailer.createTransport(smtpConfig);
    //         await transporter.sendMail(mailOptions, function (error, info) {
    //             if (error) {

    //                 console.log(error);
    //             } else {

    //                 // s3.upload(params, function (err, data) {
    //                 //   if (err) {
    //                 //     console.error("Error uploading the file: ", err);
    //                 //   } else {
    //                 //     console.log("File uploaded successfully: ", data);
    //                 //   }
    //                 // });
    //                 console.log("Email sent2: " + info.response);
    //             }
    //         });



    // }

    // const email = async () => {
    //     const transporter = nodemailer.createTransport({
    //         host: 'mail.appzoy.com',
    //         port: 465,
    //         secure: true,
    //         auth: {
    //             user: 'rajeev@appzoy.com', // Your email address
    //             pass: 'R@jeev12'
    //         },
    //     });

    //     // const emailHtml = render(<Email url="https://example.com" />);

    //     const options = {
    //         from: 'rajeev@appzoy.com',
    //         to: 'rajeevkrsingh1202@gmail.com',
    //         subject: 'hello world',
    //         html: `Hi, \n\n testing`,
    //     };

    //     await transporter.sendMail(options);
    // }
    // const handleSend = () => {
    //     email()
    // }

    const htmlMessage = `
    <div>
      <h1>Hello,!</h1>
      <p>This is a message with <strong>HTML</strong> content.</p>
      <p>Thank you for using our service!</p>
    </div>
  `;
    const sendEmails = (values) => {
        // e.preventDefault();

        let templateParams = {
            name: 'Rajeev',
            to_Email: 'rajjeevkrsingh1202@gmail.com'
        }
        // .sendForm('service_8yqrr89', 'template_5v2emwx', templateParams, {
        //     publicKey: 'Ctwy2cctwI5QrMcPg',
        //   })
        emailjs
            .send('service_8yqrr89', 'template_wnw1bzf', {
                to_name: "zeya@appzoy.com",
                from_name: "rajeev@appzoy.com",
                message: JSON.stringify(values),
                reply_to: "rajeevkumarsingh125@gmail.com"
            }, {
                publicKey: 'Ctwy2cctwI5QrMcPg',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    alert('email sent successfully')
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );
    };



    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(8, 'name  should be at least 8 characters')
            .required('Name is required *'),

        noOfDays: Yup.string()
            .min(1, 'name  should be at least 8 characters')
            .required('Name is required *'),
        // socialMediaLinks: Yup.array().of(
        //     Yup.object().shape({
        //         id: Yup.string().required(),
        //         value: Yup.string().matches(
        //             /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        //             'Enter correct url!'
        //         ).required('URL is required'),
        //     })
        // ),
        email: Yup.string().email('Invalid email').required('Email is required'),
        // checkInDate: Yup.string()
        // // .min(8, 'name  should be at least 8 characters')
        // .required('checkInDate is required *'),
        // checkOutDate: Yup.string()
        // // .min(8, 'name  should be at least 8 characters')
        // .required('checkOutDate is required *'),
    });

    // const formik = useFormik({
    //     initialValues: {
    //         name: '',
    //         email: '',
    //         noOfDays: '',
    //         formSections: [
    //             { id:  Date.now().toString(), name: '', gender: '', age: '' },
    //         ],


    //     },
    //     validationSchema,
    //     onSubmit: values => {
    //         console.log('submit clicked')
    //         sendEmails();
    //         alert(JSON.stringify(values, null, 2));
    //     },
    // });
    return (

        <div>
            {/* <Header /> */}

      
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                    name: '',
                    email: '',
                    noOfDays: '',
                    formSections: [
                        { id: Date.now().toString(), name: '', gender: '', age: '' },
                    ],

                }}

                onSubmit={values => {
                    console.log('submit clicked')
                    sendEmails();
                    alert(JSON.stringify(values, null, 2));
                }}
            >
                {({ handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched }) => (

                    <Form style={{ width: '100%', maxWidth: '500px' }} onSubmit={handleSubmit}> {/* Limit form width */}
                        <Row>
                            <Col md={12}>
                                <FormGroup floating>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Name"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}

                                    />
                                    {/* {console.log('values===', values?.name)} */}
                                    <Label for="name">
                                        Name
                                    </Label>
                                </FormGroup>
                                {' '}
                            </Col>
                        </Row>
                        <Row>
                            {errors.name && <div style={{ textAlign: 'left', marginBottom: 18, marginTop: 0 }}>
                                <span style={{ color: 'red', }}>{errors.name}</span>
                            </div>}
                        </Row>


                        <Row>
                            <Col md={12}>
                                <FormGroup floating>
                                    <Input
                                        id="exampleEmail"
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <Label for="exampleEmail">
                                        Email
                                    </Label>
                                </FormGroup>
                                {' '}
                            </Col>
                        </Row>

                        <Row>
                            {touched.email && errors.email && <div style={{ textAlign: 'left', marginBottom: 18, marginTop: 0 }}>
                                <span style={{ color: 'red' }}>{errors.email}</span>
                            </div>}
                        </Row>

                        {console.log('values========', values)}
                        {console.log('errors========', errors)}
                        {console.log('touched========', touched)}

                        <Row>
                            <Col md={12}>
                                <FormGroup floating>
                                    <Input
                                        id="noOfDays"
                                        name="noOfDays"
                                        placeholder="no of days"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.noOfDays}
                                    />
                                    <Label for="noOfDays">
                                        no of days
                                    </Label>
                                </FormGroup>
                                {' '}
                            </Col>
                        </Row>

                        <Row>
                            {touched.noOfDays && errors.noOfDays && <div style={{ textAlign: 'left', marginBottom: 18, marginTop: 0 }}>
                                <span style={{ color: 'red' }}>{errors.noOfDays}</span>
                            </div>}
                        </Row>





                        <Row>
                            <Col md={6}>
                                <FormGroup floating>
                                    <Input
                                        id="checkindate"
                                        name="checkindate"
                                        placeholder="check-in date"
                                        type="date"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.checkInDate}
                                    />
                                    <Label for="exampleEmail">
                                        Check-in Date
                                    </Label>
                                </FormGroup>
                                {' '}
                            </Col>



                            <Col md={6}>
                                <FormGroup floating>
                                    <Input
                                        id="checkoutdate"
                                        name="checkoutdate"
                                        placeholder="check-out Date"
                                        type="date"
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    // value={formik.values.checkOutDate}
                                    />
                                    <Label for="exampleEmail">
                                        Check-out Date
                                    </Label>
                                </FormGroup>
                                {' '}
                            </Col>
                        </Row>

                        <Row>
                            {(touched.checkInDate && errors.checkInDate) ||
                                (touched.checkOutDate && errors.checkOutDate)
                                && <div style={{ textAlign: 'left', marginBottom: 18, marginTop: 0 }}>
                                    <span style={{ color: 'red' }}>{errors.checkInDate}</span>
                                </div>}
                        </Row>







                        <Row>
                            <Col md={6}>
                                <div style={{
                                    padding: '10px',           // Optional: Add some padding for spacing inside the div
                                    textAlign: 'left',         // Align text to the left
                                    display: 'flex',           // Use flexbox to control the alignment if needed
                                    alignItems: 'flex-start',  // Align items to the start of the flex container
                                }}>
                                    Bedding Details
                                </div>
                            </Col>
                        </Row>


                        <FieldArray name="formSections">
                            {({ insert, remove, push }) => (
                                <div>


                                    {values.formSections.map((section, index) => (

                                        <Row id={section?.id}>
                                            <Col md={6}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="exampleEmail"
                                                        name="email"
                                                        placeholder="Email"
                                                        type="text"
                                                    />
                                                    <Label for="exampleEmail">
                                                        Name
                                                    </Label>
                                                </FormGroup>
                                                {' '}
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup style={{ position: 'relative', marginBottom: '1rem' }}>

                                                    <Input
                                                        id="exampleSelect"
                                                        name="select"
                                                        type="select"
                                                        style={{
                                                            // paddingTop: '30px', // Adjust to fit your design
                                                            paddingLeft: '10px',
                                                            height: '60px',
                                                            textAlign: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative',
                                                            appearance: 'none' // Remove default dropdown arrow
                                                        }}
                                                    >
                                                        <option value="" disabled selected >Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={3}>
                                                <FormGroup floating>
                                                    <Input
                                                        id="exampleEmail"
                                                        name="email"
                                                        placeholder="Email"
                                                        type="text"
                                                    />
                                                    <Label for="exampleEmail">
                                                        Age
                                                    </Label>
                                                </FormGroup>
                                                {' '}
                                            </Col>

                                        </Row>
                                    ))}                     
                                    <Button color="primary" onClick={() => push({ id: Date.now().toString(), name: '', gender: '', age: '' })}>
                                        Add More
                                    </Button>
                                </div>

                            )}

                        </FieldArray>






                        {/* <FormGroup floating>
                    <Input
                        id="examplePassword"
                        name="password"
                        placeholder="Password"
                        type="password"
                    />
                    <Label for="examplePassword">
                        Password
                    </Label>
                </FormGroup> */}
                        {' '}
                        <Button onClick={() => sendEmails()}>
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>

    )
}

export default Home;